<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _full-w pa-3">
      <div class="mb-3">
        <v-btn small fab depressed color="green" dark @click="getBack">
          <v-icon>mdi-reply</v-icon>
        </v-btn>
      </div>
      <v-tabs
        v-model="tab"
        class="mb-5 radius-card"
        style="overflow: hidden"
        color="purple"
        grow
      >
        <v-tab href="#tab-1"
          ><v-icon class="mr-2">mdi-account-group</v-icon><b>Anggota</b></v-tab
        >
        <v-tab href="#tab-2"
          ><v-icon class="mr-2">mdi-earth</v-icon><b>Publik</b></v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="tab" style="background: none">
        <v-tab-item value="tab-1">
          <v-card class="radius-card px-3" :loading="loader">
            <v-card-title>
              Participant List
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                solo
                dense
                rounded
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                @keyup="fetchParticipant"
              ></v-text-field>
            </v-card-title>

            <div v-if="agendaParticipant">
              <v-data-table
                :headers="
                  $route.params.status == 'gratis' ? headers_free : headers
                "
                :items="agendaParticipant.data"
                class="elevation-0 my-5"
              >
                <template v-slot:[`item.img`]="{ item }">
                  <div class="d-flex" v-if="item.relationships[1].payment.data">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          depressed
                          color="blue"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            viewBukti(
                              item.relationships[1].payment.data
                                .bukti_pembayaran
                            )
                          "
                        >
                          <v-icon dark small> mdi-image </v-icon>
                        </v-btn>
                      </template>
                      <span>Klk untuk melihat gambar</span>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-btn
                    rounded
                    depressed
                    color="green"
                    outlined
                    v-if="item.relationships[1].payment.data"
                  >
                    Paid
                  </v-btn>
                  <v-btn
                    rounded
                    depressed
                    outlined
                    v-if="!item.relationships[1].payment.data"
                  >
                    Unpaid
                  </v-btn>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        rounded
                        depressed
                        v-bind="attrs"
                        v-on="on"
                        :color="
                          item.relationships[1].payment.data
                            .status_pembayaran == 'waiting'
                            ? 'orange'
                            : item.relationships[1].payment.data
                                .status_pembayaran == 'accepted'
                            ? 'green'
                            : 'red'
                        "
                        @click="changeStatus(item)"
                        dark
                        v-if="item.relationships[1].payment.data"
                      >
                        {{
                          item.relationships[1].payment.data.status_pembayaran
                        }}
                      </v-btn>
                    </template>
                    <span>Klk untuk mengubah status</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <div class="text-center pt-2"></div>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-card class="radius-card px-3" :loading="loader">
            <v-card-title>
              Participant List
              <v-spacer></v-spacer>
              <!-- <v-text-field
                v-model="search"
                solo
                dense
                rounded
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                @keyup="fetchParticipant"
              ></v-text-field> -->
            </v-card-title>

            <div v-if="participantPublic">
              <v-data-table
                :headers="
                  $route.params.status == 'gratis'
                    ? headers_public_free
                    : headers_public
                "
                :items="participantPublic.data"
                class="elevation-0 my-5"
              >
                <template v-slot:[`item.img`]="{ item }">
                  <div class="d-flex" v-if="item.relationships[0].payment.data">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          depressed
                          color="blue"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            viewBukti(
                              item.relationships[0].payment.data
                                .bukti_pembayaran
                            )
                          "
                        >
                          <v-icon dark small> mdi-image </v-icon>
                        </v-btn>
                      </template>
                      <span>Klk untuk melihat gambar</span>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-btn
                    rounded
                    depressed
                    color="green"
                    outlined
                    v-if="item.relationships[0].payment.data"
                  >
                    Paid
                  </v-btn>
                  <v-btn
                    rounded
                    depressed
                    outlined
                    v-if="!item.relationships[0].payment.data"
                  >
                    Unpaid
                  </v-btn>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        rounded
                        depressed
                        v-bind="attrs"
                        v-on="on"
                        :color="
                          item.relationships[0].payment.data
                            .status_pembayaran === 'waiting'
                            ? 'orange'
                            : item.relationships[0].payment.data
                                .status_pembayaran === 'accepted'
                            ? 'green'
                            : 'red'
                        "
                        @click="changeStatus(item, true)"
                        dark
                        v-if="item.relationships[0].payment.data"
                      >
                        {{
                          item.relationships[0].payment.data.status_pembayaran
                        }}
                      </v-btn>
                    </template>
                    <span>Klk untuk mengubah status</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <div class="text-center pt-2"></div>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-toolbar dense flat>
          <h3>Bukti Pembayaran</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="dialog = false"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <div class="d-flex justify-center">
            <img :src="dataBukti" height="350px" alt="" />
          </div>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStatus" max-width="500">
      <v-card>
        <v-toolbar dense flat>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="dialogStatus = false"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <div class="d-flex justify-center align-center" style="height: 150px">
            <v-btn
              rounded
              outlined
              class="mr-3"
              color="green"
              @click="updateStatusParticipant('accepted')"
            >
              <v-icon>mdi-check</v-icon> Accept</v-btn
            >
            <v-btn
              rounded
              outlined
              color="red"
              @click="updateStatusParticipant('rejected')"
              ><v-icon>mdi-cancel</v-icon> Reject</v-btn
            >
          </div>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "listParticipantAgenda",
  computed: {
    ...mapState({
      agendaParticipant: state => state.agenda.agendaParticipant,
      participantPublic: state => state.agenda.participantPublic,
      role: state => state.role
    })
  },
  data() {
    return {
      search: "",
      loader: false,
      dialog: false,
      dialogStatus: false,
      dataBukti: null,
      dataPayer: null,
      statusPayer: null,
      tab: null,
      headers: [
        { text: "ID", value: "id", sortable: false },
        {
          text: "NAME",
          value: "relationships[0].user.data.nama_lengkap",
          sortable: false
        },
        {
          text: "PHONE",
          value: "relationships[0].user.data.no_hp",
          sortable: false
        },
        {
          text: "EMAIL",
          value: "relationships[0].user.data.email",
          sortable: false
        },
        { text: "STATUS", value: "status", align: "start", sortable: false },
        { text: "BUKTI TF", value: "img", align: "start", sortable: false },
        { text: "ACTION", value: "action", align: "center", sortable: false }
      ],
      headers_free: [
        { text: "ID", value: "id", sortable: false },
        {
          text: "NAME",
          value: "relationships[0].user.data.nama_lengkap",
          sortable: false
        },
        {
          text: "PHONE",
          value: "relationships[0].user.data.no_hp",
          sortable: false
        },
        {
          text: "EMAIL",
          value: "relationships[0].user.data.email",
          sortable: false
        }
      ],
      headers_public: [
        { text: "ID", value: "id", align: "start", sortable: false },
        {
          text: "NAME",
          value: "nama_lengkap",
          align: "start",
          sortable: false
        },
        { text: "PHONE", value: "no_hp", align: "start", sortable: false },
        { text: "EMAIL", value: "email", sortable: false },
        { text: "STATUS", value: "status", align: "start", sortable: false },
        { text: "BUKTI TF", value: "img", align: "start", sortable: false },
        { text: "ACTION", value: "action", align: "center", sortable: false }
      ],
      headers_public_free: [
        { text: "ID", value: "id", align: "start", sortable: false },
        {
          text: "NAME",
          value: "nama_lengkap",
          align: "start",
          sortable: false
        },
        { text: "PHONE", value: "no_hp", align: "start", sortable: false },
        { text: "EMAIL", value: "email", sortable: false }
      ]
    };
  },
  mounted() {
    this.fetchParticipant();
    this.fetchParticipantPublic();
  },
  methods: {
    getBack() {
      this.$router.go(-1);
    },
    fetchParticipant() {
      let data = {
        page: this.page,
        per_page: this.perPage,
        search: this.search,
        id: this.$route.params.id
      };
      this.loader = true;
      this.$store.dispatch("agenda/listParticipant", data).then(() => {
        this.loader = false;
      });
    },
    fetchParticipantPublic() {
      this.loader = true;
      this.$store
        .dispatch("agenda/listParticipantPublic", this.$route.params.id)
        .then(() => {
          this.loader = false;
        });
    },
    viewBukti(data) {
      this.dataBukti = data;
      this.dialog = true;
    },
    changeStatus(data, isPublic) {
      console.log(isPublic);
      if (isPublic) {
        this.dataPayer = data.relationships[0].payment.data.id;
      } else {
        this.dataPayer = data.relationships[1].payment.data.id;
      }
      this.dialogStatus = true;
    },
    updateStatusParticipant(status) {
      this.loader = true;
      let data = {
        id: this.dataPayer,
        status_pembayaran: status
      };
      this.$store
        .dispatch("agenda/updatePayer", data)
        .then(data => {
          console.log(data.data);
          this.dialogStatus = false;
          this.loader = false;
          this.fetchParticipant();
          this.fetchParticipantPublic();
          Swal.fire({
            icon: "success",
            title: data.data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        })
        .catch(err => {
          this.dialogStatus = false;
          this.loader = false;
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "error",
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        });
    }
  }
};
</script>

<style></style>
